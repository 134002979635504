import * as React from 'react';
import * as moment from 'moment';

import * as style from './UserInfo.scss';

import {
    Button,
    ButtonTheme,
    InputContainer,
    MASK,
    MaskedInput,
    Heading,
    DatePicker_redesign as Datepicker,
} from '@sm/ui';

import { AccountStatus } from '@sm/types/admin';

const MAX_NAME_LENGTH = 255;

export interface UserInfoParams {
    isNew?: boolean;
    firstName: string;
    middleName: string;
    secondName: string;
    email: string;
    phoneNumber: string;
    title?: string;
    status: AccountStatus;
    activeUntil: moment.Moment;
}

interface Props extends UserInfoParams {
    onFirstNameChange: (value: string) => void;
    onMiddleNameChange: (value: string) => void;
    onSecondNameChange: (value: string) => void;
    onEmailChange: (value: string) => void;
    onPhoneChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onConfirmClick: () => void;
    onCancelClick: () => void;
    onDateChange: (activeUntil: moment.Moment) => void;
    isOnValidation: boolean;
    isFirstNameValid: boolean;
    isSecondNameValid: boolean;
    isEmailValid: boolean;
    isPhoneNumberValid: boolean;
}

interface TitleInputProps {
    title: string;
    value: string;
    placeholder: string;
    isOnValidation: boolean;
    isValid: boolean;
    disabled?: boolean;
    onChange: (value: string) => void;
}

export function UserInfo({
    isNew,
    title,
    firstName,
    middleName,
    secondName,
    email,
    phoneNumber,
    status,
    activeUntil,
    isFirstNameValid,
    isSecondNameValid,
    isEmailValid,
    isPhoneNumberValid,
    isOnValidation,
    onFirstNameChange,
    onSecondNameChange,
    onMiddleNameChange,
    onEmailChange,
    onPhoneChange,
    onConfirmClick,
    onCancelClick,
    onDateChange,
}: Props): JSX.Element {
    return (
        <div className={style.root}>
            {title && <div className={style.titleWrapper}>{title}</div>}

            <TitleInput
                title="Фамилия"
                value={secondName}
                placeholder="Введите фамилию"
                isOnValidation={isOnValidation}
                isValid={isSecondNameValid}
                onChange={onSecondNameChange}
            />

            <TitleInput
                title="Имя"
                value={firstName}
                placeholder="Введите имя"
                isOnValidation={isOnValidation}
                isValid={isFirstNameValid}
                onChange={onFirstNameChange}
            />

            <TitleInput
                title="Отчество"
                value={middleName}
                placeholder="Введите отчество"
                isOnValidation={isOnValidation}
                isValid
                onChange={onMiddleNameChange}
            />

            <div className={style.separateLine} />

            <TitleInput
                title="Электронная почта"
                value={email}
                placeholder="Введите email"
                isOnValidation={isOnValidation}
                isValid={isEmailValid}
                onChange={onEmailChange}
                disabled={!isNew}
            />

            <div className={style.inputBlockWrapper}>
                <Heading title="Номер контактного телефона" showError={!(!isOnValidation || isPhoneNumberValid)}>
                    <div className={style.inputWrapper}>
                        <MaskedInput
                            value={phoneNumber}
                            mask={MASK.phone}
                            placeholder="Введите номер телефона"
                            onChange={onPhoneChange}
                        />
                    </div>
                </Heading>
            </div>

            <div className={style.inputBlockWrapper}>
                <div className={style.fieldName}>Срок жизни учетной записи</div>

                <div className={style.datepickerWrapper}>
                    <Datepicker date={activeUntil} onChange={onDateChange} />
                </div>
            </div>

            <div className={style.inputBlockWrapper}>
                <div className={style.fieldName}>Статус пользователя</div>
                <InputContainer value={status == AccountStatus.ACTIVE ? 'Активен' : 'Заблокирован'} readOnly />
            </div>

            <div className={style.buttonsWrapper}>
                <div className={style.buttonWrapper}>
                    <Button theme={ButtonTheme.PrimaryMain} onClick={onConfirmClick}>
                        Сохранить
                    </Button>
                </div>

                <div className={style.buttonWrapper}>
                    <Button theme={ButtonTheme.PrimaryOutline} onClick={onCancelClick}>
                        Отменить
                    </Button>
                </div>
            </div>
        </div>
    );
}

function TitleInput({
    title,
    value,
    placeholder,
    isOnValidation,
    isValid,
    disabled,
    onChange,
}: TitleInputProps): JSX.Element {
    return (
        <div className={style.inputBlockWrapper}>
            <Heading title={title} showError={!(!isOnValidation || isValid)}>
                <InputContainer
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    maxLength={MAX_NAME_LENGTH}
                    disabled={disabled}
                />
            </Heading>
        </div>
    );
}
