// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-UsersPage-_UsersPage__root{display:flex;flex-direction:column}.src-modules-UsersPage-_UsersPage__contentWrapper{display:flex;flex-direction:column;min-width:1000px;margin:auto;align-items:center}\n", "",{"version":3,"sources":["webpack://./src/modules/UsersPage/UsersPage.scss"],"names":[],"mappings":"AAAA,wCACI,YAAa,CACb,qBAAsB,CACzB,kDAGG,YAAa,CACb,qBAAsB,CAEtB,gBAAiB,CACjB,WAAY,CAEZ,kBAAmB","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n}\n\n.contentWrapper {\n    display: flex;\n    flex-direction: column;\n\n    min-width: 1000px;\n    margin: auto;\n\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-UsersPage-_UsersPage__root",
	"contentWrapper": "src-modules-UsersPage-_UsersPage__contentWrapper"
};
export default ___CSS_LOADER_EXPORT___;
