import * as React from 'react';

import * as style from './NotFoundPage.scss';

import { Page } from '../common/Page';

export function NotFoundPage(): JSX.Element {
    return (
        <Page withoutHeader>
            <div className={style.root}>
                <span className={style.code}>404</span>
                <span className={style.message}>Страница не найдена.</span>
            </div>
        </Page>
    );
}
