import axios from 'axios';

import { OrganizationParams } from '@sm/types/admin';

export class OrganizationApi {
    public static async getById(id: string): Promise<OrganizationParams> {
        const { data } = await axios.get<OrganizationParams>(`/api/organization/${id}`);
        return data;
    }

    public static async getList(): Promise<OrganizationParams[]> {
        const { data } = await axios.get<OrganizationParams[]>(`/api/organization`);
        return data;
    }
}
