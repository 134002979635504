import axios, { AxiosResponse } from 'axios';

import { store } from '../../store';
import { addError } from '../../store/common/actions';

const UNAUTHORIZED_CODE = 401;
const GATEWAY_TIMEOUT = 504;

const baseURL: string = window && window['BASE_URL'] ? window['BASE_URL'] : '';

axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store, must-reinvalidate';
axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Expires'] = 0;

const instance = axios.create({ baseURL });

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status, data } = error.response;

        if (status == UNAUTHORIZED_CODE) {
            window.location.pathname = '/login';
        }

        if (status === GATEWAY_TIMEOUT) {
            store.dispatch(addError('GatewayTimeout'));

            return Promise.reject(error.message);
        }
        store.dispatch(addError(data.data.code));

        return Promise.reject(new Error(data.data.data.message));
    },
);

export { instance, AxiosResponse };
