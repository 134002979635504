import * as React from 'react';
import * as classNames from 'classnames';

import * as style from './SelectMenu.scss';
import { Icon, IconType } from '@sm/ui';

export interface SelectMenuParams {
    items: SelectMenuItemParams[];
    openerClass?: string;
    openerIcon?: IconType;
}

export interface SelectMenuItemParams {
    type?: string;
    href?: string;
    label: string;
    disabled?: boolean;
    onClick?: () => void;
}

interface SelectMenuProps extends SelectMenuParams {
    isOpen: boolean;
    onOpenIconClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    onClick: (func: () => void, event: React.MouseEvent<HTMLDivElement>) => void;
}

export function SelectMenu({
    isOpen,
    items,
    onOpenIconClick,
    onClick,
    openerClass,
    openerIcon = IconType.KEBAB,
}: SelectMenuProps): JSX.Element {
    return (
        <div className={style.root}>
            <div className={style.opener} onClick={onOpenIconClick}>
                <div className={isOpen ? style.iconWrapperOnOpen : style.iconWrapper}>
                    <Icon type={openerIcon} />
                </div>
            </div>
            <div className={classNames([style.content, openerClass, isOpen && style.contentOpen])}>
                {items.map((item) =>
                    item.type == 'LINK' ? (
                        <a key={item.label} className={style.menuItem} href={item.href} download="file">
                            {item.label}
                        </a>
                    ) : (
                        <MenuItem key={item.label} item={item} handleOnClick={onClick} />
                    ),
                )}
                <div className={style.mask} onClick={onOpenIconClick} />
            </div>
        </div>
    );
}

interface MenuItemPorps {
    item: SelectMenuItemParams;
    handleOnClick: (func: () => void, event: React.MouseEvent<HTMLDivElement>) => void;
}

function MenuItem({ item, handleOnClick }: MenuItemPorps): JSX.Element {
    function handleClick(event: React.MouseEvent<HTMLDivElement>) {
        handleOnClick(item.onClick, event);
    }

    return (
        <div
            className={classNames([style.menuItem, item.disabled && style.disabledItem])}
            onClick={item.disabled ? null : handleClick}
        >
            {item.label}
        </div>
    );
}
