import * as React from 'react';
import { connect, Dispatch } from 'react-redux';
import autobind from 'autobind-decorator';
import * as lodash from 'lodash';
import * as moment from 'moment';

import { AccountStatus } from '@sm/types/openid';
import { UserAttributes, UpdateUserAttributes } from '@sm/types/admin';

import { UserApi } from '../../../api';
import { StoreState } from '../../../store';
import { UpdateUserParams } from '../../../store/userPage/types';
import { getUser } from '../../../store/userPage/selector';
import { updateUser } from '../../../store/userPage/actions';

import { UserForm } from './UserForm';

interface Props extends MapProps, DispatchProps {
    userId: number;
    onCancelClick?: () => void;
}

interface MapProps {
    user?: UserAttributes;
}

interface DispatchProps {
    updateUser?: (user: UpdateUserParams) => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class EditUserFormContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    public render(): JSX.Element {
        return React.createElement(UserForm, {
            userId: this.props.userId,
            firstName: this.props.user.firstName || '',
            middleName: this.props.user.middleName || '',
            secondName: this.props.user.secondName || '',
            email: this.props.user.email || '',
            phoneNumber: this.props.user.phoneNumber,
            status: this.props.user.status || AccountStatus.ACTIVE,
            activeUntil: moment(this.props.user.activeUntil),
            onConfirmClick: this.handleConfirmClick,
            onCancelClick: this.handleCancelClick,
        });
    }

    @autobind
    protected async handleConfirmClick(params: UpdateUserAttributes) {
        const userParams: UpdateUserAttributes = this.makeUpdateUserParams(params);

        if (Object.keys(userParams).length) {
            const id = this.props.userId;

            const { status } = await UserApi.updateUser(id, userParams);

            this.props.updateUser({ id, status, ...userParams });

            this.props.onCancelClick();
        }
    }

    @autobind
    protected handleCancelClick() {
        this.props.onCancelClick();
    }

    private makeUpdateUserParams({ ...params }: UpdateUserAttributes): UpdateUserAttributes {
        const userParams: UpdateUserAttributes = lodash.reduce<any, any>(
            params,
            (acc, value, key) => {
                const valueIsDate = moment(value).isValid();

                const valueChanged = valueIsDate
                    ? !moment(this.props.user[key]).isSame(moment(value))
                    : this.props.user[key] != value;

                if (valueChanged) {
                    acc[key] = value;
                }
                return acc;
            },
            {},
        );

        return userParams;
    }
}

function mapStateToProps(state: StoreState, ownProps: Props): MapProps {
    return {
        user: getUser(state, ownProps.userId),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        updateUser: (user: UpdateUserParams) => dispatch(updateUser(user)),
    };
}
