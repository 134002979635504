import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import * as lodash from 'lodash';

import * as Organization from '@sm/types/admin';
import { OrganizationState } from '../types';
import { StoreState } from '../../';

import { Utils } from '../../../modules/common/Utils';

const getOrganizationState = (state: StoreState): OrganizationState => state.organization;

export const getOrganizationList = createSelector(
    getOrganizationState,
    (state: OrganizationState): Organization.OrganizationParams[] => {
        return lodash.sortBy(state.organizations, 'name');
    },
);

export const getOrganizationById = createCachedSelector(
    getOrganizationList,
    (state: StoreState, id: string): string => id,
    (orgzanizations: Organization.OrganizationParams[], id: string): Organization.OrganizationParams => {
        return Utils.getItemById(orgzanizations, id);
    },
)((state: StoreState, id: string): string => id);

export const getCurrentOrganization = createSelector(
    getOrganizationState,
    (state: OrganizationState): Organization.OrganizationParams => {
        return state.currentOrganization;
    },
);

export const getCurrentOrganizationId = createSelector(getOrganizationState, (state: OrganizationState): string => {
    return state.currentOrganizationId;
});

export const getIsFormOpen = createSelector(getOrganizationState, (state: OrganizationState): boolean => {
    return state.isFormOpen;
});
