import { ComponentType } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { StoreState } from '../../../store';
import { getLoginUser } from '../../../store/user/selector';
import { User } from '../../../store/user/types';
import { loadUser } from '../../../store/user/actions';
import { AuthErrorPageContainer } from './AuthErrorPageContainer';
import { AuthErrorPageMappedActions, AuthErrorPageMappedState } from './types';

const mapStateToProps = (state: StoreState): AuthErrorPageMappedState => {
    const user = getLoginUser(state);
    return {
        userFirstName: user && user.firstName,
        userSecondName: user && user.secondName,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<StoreState>): AuthErrorPageMappedActions => ({
    loadUser: (user: User) => dispatch(loadUser(user)),
});

const withStore = connect(mapStateToProps, mapDispatchToProps);

/** Auth error page */
export const AuthErrorPageStore: ComponentType = withRouter(withStore(AuthErrorPageContainer));
