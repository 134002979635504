import { combineReducers, createStore, Store } from 'redux';

import { userReducer } from './user/reducers';
import { UserState } from './user/types';
import { userPageReducer } from './userPage/reducers';
import { UserPageState } from './userPage/types';
import { commonReducer } from './common/reducers';
import { CommonState } from './common/types';
import { organizationReducer } from './organization/reducers';
import { OrganizationState } from './organization/types';

export interface StoreState {
    user?: UserState;
    userPage?: UserPageState;
    common?: CommonState;
    organization?: OrganizationState;
}

const reducer = combineReducers({
    user: userReducer,
    userPage: userPageReducer,
    common: commonReducer,
    organization: organizationReducer,
});

const isProd = process.env.NODE_ENV == 'production';
const enhancer = window['__REDUX_DEVTOOLS_EXTENSION__'] && window['__REDUX_DEVTOOLS_EXTENSION__']();

export const store: Store<StoreState> = isProd ? createStore(reducer) : createStore(reducer, enhancer);
