import * as React from 'react';
import { connect, Dispatch } from 'react-redux';
import autobind from 'autobind-decorator';
import * as moment from 'moment';

import { CreateUserParams, UserParams, AccountStatus } from '@sm/types/admin';

import { UserApi } from '../../../api';
import { StoreState } from '../../../store';
import { addUser } from '../../../store/userPage/actions';

import { UserForm } from './UserForm';
import { UserValidation } from './UserValidation';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {
    onCancelClick(): void;
}

interface MapProps {
    usedEmails: string[];
}

interface DispatchProps {
    addUser(user: UserParams): void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class CreateUserFormContainer extends React.Component<Props> {
    public render(): JSX.Element {
        return React.createElement(UserForm, {
            isNew: true,
            firstName: '',
            middleName: '',
            secondName: '',
            email: '',
            phoneNumber: '',
            status: AccountStatus.ACTIVE,
            activeUntil: moment().add(1, 'month'),
            usedEmails: this.props.usedEmails,
            onConfirmClick: this.handleConfirmClick,
            onCancelClick: this.handleCancelClick,
        });
    }

    @autobind
    protected async handleConfirmClick(params: CreateUserParams) {
        const isValid = UserValidation.validateUser(params);

        if (isValid) {
            const user = await UserApi.createUser(params);

            this.props.addUser(user);
            this.props.onCancelClick();
        }
    }

    @autobind
    protected handleCancelClick() {
        this.props.onCancelClick();
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const { users } = state.userPage;

    return {
        usedEmails: users.map((item) => item.email),
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        addUser: (user: UserParams) => dispatch(addUser(user)),
    };
}
