import * as React from 'react';
import * as moment from 'moment';

import * as style from './UserRow.scss';

import { UserAttributes, AccountStatus } from '@sm/types/admin';

import { Popup } from '@sm/ui';
import { EditUserForm } from '../UserForm';
import { SelectMenu, SelectMenuItemParams } from '../../common/SelectMenu';

interface Props {
    user: UserAttributes;
    selectMenuParams: SelectMenuItemParams[];
    isOnEdit: boolean;
    onCancelClick: () => void;
}

export const AccountStatusDictionary = {
    [AccountStatus.ACCESS_DENIED]: 'Заблокирован',
    [AccountStatus.ACTIVE]: 'Активен',
    [AccountStatus.RENEWAL_REQUESTED]: 'Запрошено обновление',
    [AccountStatus.EXPIRED]: 'Истёк срок действия',
    [AccountStatus.BLOCKED_FOR_INCORRECT_CREDENTIALS]: 'Заблокирован (ввод неправильного пароля)',
    [AccountStatus.NEED_UPDATE_PASSWORD]: 'Смена пароля',
};

export function UserRow({ user, selectMenuParams, isOnEdit, onCancelClick }: Props): JSX.Element {
    return (
        <div className={style.root}>
            {isOnEdit && (
                <Popup onOutOfContentClick={onCancelClick}>
                    <EditUserForm userId={user.id} onCancelClick={onCancelClick} />
                </Popup>
            )}
            <div className={style.idColumnWrapper}>
                <div className={style.textWrapper}>{user.id}</div>
            </div>
            <div className={style.nameColumnWrapper}>
                <div className={style.textWrapper}>
                    {user.secondName} {user.firstName} {user.middleName}
                </div>
            </div>
            <div className={style.emailColumnWrapper}>
                <div className={style.textWrapper}>{user.email}</div>
            </div>
            <div className={style.columnWrapper}>
                <div className={style.textWrapper}>{user.phoneNumber}</div>
            </div>
            <div className={style.columnWrapper}>
                <div className={style.textWrapper}>{AccountStatusDictionary[user.status]}</div>
            </div>
            <div className={style.columnWrapper}>
                <div className={style.textWrapper}>{moment(user.activeUntil).format('до DD/MMM/YYYY')}</div>
            </div>
            <div className={style.menuColumnWrapper}>
                <div className={style.selectMenuWrapper}>
                    <SelectMenu items={selectMenuParams} />
                </div>
            </div>
        </div>
    );
}
