import * as React from 'react';

import * as style from './OrganizationsPage.scss';

import { OrganizationParams } from '@sm/types/admin';

import { Page } from '../common/Page';
import { OrganizationCard } from './OrganizationCard';

interface Props {
    organizations: OrganizationParams[];
    preloader: boolean;
}

export function OrganizationsPage({ preloader, organizations }: Props): JSX.Element {
    return (
        <Page withoutBackButton withoutNavigation pageLabel="Организации" preloader={preloader}>
            {!preloader && (
                <div className={style.root}>
                    <div className={style.contentWrapper}>
                        {organizations.map((item) => (
                            <div key={item.id} className={style.cardWrapper}>
                                <OrganizationCard organization={item} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Page>
    );
}
