import * as React from 'react';
import * as url from 'url';
import { connect, Dispatch } from 'react-redux';
import autobind from 'autobind-decorator';
import { RouteProps, RouteComponentProps, Route } from 'react-router-dom';
import { isEmpty, includes } from 'lodash';

import { AuthResponse, RoleAttributes } from '@sm/types/admin';
import { RoleId } from '@sm/types/backend';

import { StyledPopup } from '@sm/ui';
import { PageProps } from '../Page';
import { AuthApi } from '../../../api';
import { loadUser } from '../../../store/user/actions';
import { getLoginUser } from '../../../store/user/selector';
import { User } from '../../../store/user/types';
import { StoreState } from '../../../store';

interface Props extends RouteProps, PageProps, MapProps, DispatchProps {
    component: React.ComponentType<PageProps>;
    children?: React.ReactNode;
}

interface MapProps {
    user?: User;
}

interface DispatchProps {
    loadUser?: (user: User) => void;
}

interface State {
    onLoad: boolean;
    timeLeft: number;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class RouteContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            onLoad: null,
            timeLeft: 1,
        };
    }

    public async componentDidMount() {
        const userIsNotLoaded = this.props.user == null;

        if (userIsNotLoaded) {
            await this.loadUser();
        }

        this.startSessionCount();
        this.setState({ onLoad: false });
    }

    public render() {
        const { location, path, exact, strict } = this.props;

        return React.createElement(Route, {
            location,
            path,
            exact,
            strict,
            render: this.renderContent,
        });
    }

    @autobind
    protected async getLogoutUrl() {
        const url = await AuthApi.logout();

        return url;
    }

    @autobind
    protected async getUser() {
        return this.props.user;
    }

    @autobind
    protected renderContent(routeProps: RouteComponentProps<any>) {
        let content: JSX.Element | {}[] = null;

        if (this.state.onLoad !== null) {
            if (isEmpty(this.props.user)) {
                this.redirectToLogin(routeProps);
            } else if (!this.isAccountAdmin()) {
                this.redirectToAuthError();
            } else {
                content = this.renderPage(routeProps);
            }
        }

        return content;
    }

    protected redirectToAuthError(): void {
        const { protocol, host } = url.parse(window.location.href);
        window.location.href = url.format({
            protocol,
            host,
            pathname: '/auth-error',
            query: {
                type: 'no-access',
            },
        });
    }

    protected isAccountAdmin(): boolean {
        const { user } = this.props;
        const roles: RoleAttributes[] = user.roles || [];
        const rolesIds = roles.map(({ id }) => id);
        return includes(rolesIds, RoleId.AccountAdmin);
    }

    protected renderPage(routeProps: RouteComponentProps<any>) {
        const { component, ...props } = this.props;

        return [
            React.createElement(this.props.component, {
                ...routeProps,
                ...props,
                getLogoutUrl: this.getLogoutUrl,
                getUser: this.getUser,
                key: 'content',
            } as any),
            this.renderModalNotification(),
        ];
    }

    protected async redirectToLogin(routeProps: RouteComponentProps<any>) {
        let from: string = routeProps.location.pathname;
        if (!isEmpty(routeProps.location.search) && routeProps.location.search !== '?') {
            from = `${from}${routeProps.location.search}`;
        }
        AuthApi.login(from);
    }

    protected renderModalNotification(): React.ReactNode {
        if (this.state.timeLeft <= 0) {
            return React.createElement(
                StyledPopup,
                {
                    key: 'popup',
                    title: 'Пожалуйста, войдите заново',
                    fullMessage:
                        'Ваша сессия истекла. Пожалуйста, авторизуйтесь заново, ' +
                        'это необходимо для безопасности вашего аккаунта',
                    firstButtonText: 'Авторизоваться',
                    firstButtonHandler: AuthApi.logout,
                },
                null,
            );
        }
        return null;
    }

    @autobind
    protected handleRedirect() {
        this.props.loadUser(null);
    }

    private async loadUser() {
        let user;

        try {
            const authorizedUser: AuthResponse = await AuthApi.getAuthorizedUser();
            user = authorizedUser.user;
        } catch (error) {
            user = null;
        }

        this.props.loadUser(user);
    }

    private async startSessionCount() {
        const timeLeft = await AuthApi.getTimeLeft();

        this.setState({ timeLeft });

        if (timeLeft > 0) {
            setTimeout(() => this.startSessionCount(), timeLeft);
        }
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        user: getLoginUser(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return {
        loadUser: (user: User) => dispatch(loadUser(user)),
    };
}
