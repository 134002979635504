import * as url from 'url';
import axios from 'axios';
import { AuthResponse, AuthorizationTimeLeftResponse } from '@sm/types/admin';

const BASE_URL = '/api/auth';

export class AuthApi {
    public static async getAuthorizedUser(): Promise<AuthResponse> {
        const { data } = await axios.get<AuthResponse>(BASE_URL);

        return data;
    }

    public static async getTimeLeft(): Promise<number> {
        const { data } = await axios.get<AuthorizationTimeLeftResponse>(`${BASE_URL}/time-left`);

        return data.timeLeft;
    }

    public static logout(): void {
        window.location.href = `${BASE_URL}/logout`;
    }

    public static login(from: string): void {
        const { protocol, host } = url.parse(window.location.href);
        window.location.href = url.format({
            protocol,
            host,
            pathname: `${BASE_URL}/login`,
            query: {
                from: encodeURIComponent(from),
            },
        });
    }
}
