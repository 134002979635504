import * as React from 'react';

import * as style from './TopLine.scss';

import { Button, ButtonTheme } from '@sm/ui';

interface TopLineProps {
    onNewUserButtonClick: () => void;
}

export function TopLine({ onNewUserButtonClick }: TopLineProps): JSX.Element {
    return (
        <div className={style.root}>
            <div className={style.buttonBlock}>
                <div className={style.buttonWrapper}>
                    <Button theme={ButtonTheme.PrimaryMain} onClick={onNewUserButtonClick}>
                        Новый пользователь
                    </Button>
                </div>
            </div>
        </div>
    );
}
