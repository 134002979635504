import * as React from 'react';
import inlineSvgSprite from 'inline-svg-sprite';
import { PagePreloader } from '@sm/ui';

import * as style from './Page.scss';
import { HeaderContainer } from '../Header';
import { Navigation } from '../Navigation';

import 'sber-marketing-ui/public/ui-components.css';
import 'minireset.css/minireset.css';

export interface PageProps {
    preloader?: boolean;
    children?: React.ReactNode;
    pageLabel?: string;
    withoutHeader?: boolean;
    withoutNavigation?: boolean;
    previousUrl?: string;
    previousLabel?: string;
    withoutBackButton?: boolean;
}

export function Page({
    preloader = false,
    children,
    withoutHeader,
    pageLabel,
    withoutNavigation,
    withoutBackButton,
    previousUrl,
    previousLabel,
}: PageProps): JSX.Element {
    return (
        <div className={style.root}>
            {!withoutHeader && (
                <HeaderContainer
                    pageLabel={pageLabel}
                    withoutBackButton={withoutBackButton}
                    previousLabel={previousLabel}
                    previousUrl={previousUrl}
                />
            )}
            <div className={style.contentWrapper}>
                {!withoutNavigation && (
                    <div className={style.navigation}>
                        <Navigation />
                    </div>
                )}
                <div className={style.content}>{children}</div>
            </div>

            {preloader && (
                <div className={style.preloader}>
                    <PagePreloader />
                </div>
            )}

            <div className={style.inlineIcons} dangerouslySetInnerHTML={{ __html: inlineSvgSprite }} />
        </div>
    );
}
