// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-common-ErrorHandler-_ErrorHandler__root{width:100%;height:1000%}.src-modules-common-ErrorHandler-_ErrorHandler__contentHidden{display:block}\n", "",{"version":3,"sources":["webpack://./src/modules/common/ErrorHandler/ErrorHandler.scss"],"names":[],"mappings":"AAAA,qDACI,UAAW,CACX,YAAa,CAChB,8DAGG,aAAc","sourcesContent":[".root {\n    width: 100%;\n    height: 1000%;\n}\n\n.contentHidden {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-common-ErrorHandler-_ErrorHandler__root",
	"contentHidden": "src-modules-common-ErrorHandler-_ErrorHandler__contentHidden"
};
export default ___CSS_LOADER_EXPORT___;
