import * as React from 'react';
import * as moment from 'moment';

import * as style from './UserForm.scss';

import { AccountStatus } from '@sm/types/openid';
import { CreateUserParams } from '@sm/types/admin';

import { UserInfo } from './UserInfo';

export interface UserFormParams {
    isNew?: boolean;
    firstName: string;
    middleName: string;
    secondName: string;
    email: string;
    phoneNumber: string;
    userId?: number;
    status?: AccountStatus;
    activeUntil?: moment.Moment;
}

interface UserFormProps extends UserFormParams {
    usedEmails?: string[];
    onConfirmClick(params: CreateUserParams): void;
    onCancelClick(): void;
}

export function UserForm({
    isNew,
    userId,
    firstName,
    middleName,
    secondName,
    email,
    phoneNumber,
    status,
    activeUntil,
    usedEmails,
    onConfirmClick,
    onCancelClick,
}: UserFormProps): JSX.Element {
    return (
        <div className={style.root}>
            {!userId && <div className={style.titleWrapper}>Создание нового профиля</div>}
            <UserInfo
                isNew={isNew}
                title={userId ? 'Профиль пользователя' : null}
                onConfirmClick={onConfirmClick}
                onCancelClick={onCancelClick}
                firstName={firstName}
                middleName={middleName}
                secondName={secondName}
                email={email}
                phoneNumber={phoneNumber}
                status={status}
                activeUntil={activeUntil}
                usedEmails={usedEmails}
            />
        </div>
    );
}
