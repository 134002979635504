import * as React from 'react';

import * as style from './UsersTable.scss';

import { UserAttributes } from '@sm/types/admin';

import { UserRow } from '../UserRow';

interface Props {
    users: UserAttributes[];
}

export function UsersTable({ users }: Props): JSX.Element {
    return (
        <div className={style.root}>
            <div className={style.titleRow}>
                <div className={style.idColumnWrapper}>Id</div>
                <div className={style.nameColumnWrapper}>ФИО</div>
                <div className={style.emailColumnWrapper}>Электронная почта</div>
                <div className={style.columnWrapper}>Контактный телефон</div>
                <div className={style.columnWrapper}>Статус</div>
                <div className={style.columnWrapper}>Время жизни</div>
            </div>
            {users.map((user) => (
                <div key={user.id}>
                    <UserRow user={user} />
                </div>
            ))}
        </div>
    );
}
