import * as React from 'react';
import { NavLink } from 'react-router-dom';
import * as Dotdotdot from 'react-dotdotdot';

import * as style from './OrganizationCard.scss';

import { OrganizationParams } from '@sm/types/admin';

import { getDeclension } from '../../common/Utils';
import { Card } from '../../common/Card';

export interface Props {
    organization: OrganizationParams;
}

const NAME_CLAMP = 2;

export function OrganizationCard({ organization }: Props): JSX.Element {
    const { id, name, userCount = 0 } = organization;
    const userDeclension = getDeclension(userCount, ['пользователь', 'пользователя', 'пользователей']);

    return (
        <div className={style.root}>
            <Card>
                <NavLink className={style.link} to={`/organization/${id}/user`}>
                    <div className={style.name}>
                        <Dotdotdot clamp={NAME_CLAMP}>{name}</Dotdotdot>
                    </div>
                    <div className={style.userCount}>
                        {userCount} {userDeclension}
                    </div>
                </NavLink>
            </Card>
        </div>
    );
}
