const PHONE_LENGTH = 11;

export class Validation {
    public static validateEmail(text: string): boolean {
        const emailRegex = /^([\w-\.]+(\+\d+)?@([\w-]+\.)+[\w-]{2,10})?$/;
        return emailRegex.test(text);
    }

    public static validatePhoneNumber(text: string): boolean {
        const phone = text.replace(/\D/g, '');
        return phone.length == PHONE_LENGTH;
    }

    public static validateNumber(text: string): boolean {
        const regex = /^\d+$/;
        return regex.test(text);
    }

    public static validateFloatNumber(text: string): boolean {
        const regex = /^((\d)*[.,]?(\d)*)?$/;
        return regex.test(text);
    }

    public static validateWebsite(website: string): boolean {
        const regWebsite = /^(([\w-]+\.)+[\w-]{2,4})?$/;

        return regWebsite.test(website.replace('://', ''));
    }

    public static notEmpty(value: string): boolean {
        return value != '';
    }
}
