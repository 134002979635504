import { createSelector } from 'reselect';

import { StoreState } from '../../';
import { User } from '../types';

const getUser = (state: StoreState): User => state.user;

export const getLoginUser = createSelector(getUser, (user: User): User => {
    return user;
});
