import * as React from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';

import { UserAttributes, AccountStatus } from '@sm/types/admin';

import { UsersTable } from './UsersTable';
import { getUsers } from '../../../store/userPage/selector';
import { StoreState } from '../../../store';

interface Props extends Partial<StateProps> {}

interface StateProps {
    users: UserAttributes[];
}

@(connect(mapStateToProps, null) as any)
export class UsersTableContainer extends React.Component<Props> {
    public render(): JSX.Element {
        const { users } = this.props;
        return React.createElement(UsersTable, {
            users: lodash.sortBy(users, (item) => item.status != AccountStatus.RENEWAL_REQUESTED),
        });
    }
}

function mapStateToProps(state: StoreState): StateProps {
    return {
        users: getUsers(state),
    };
}
