import { actionCreatorFactory } from 'typescript-fsa';

import { UserParams } from '@sm/types/admin';
import { UserPageLoadParams, UpdateUserParams } from '../types';

const actionCreator = actionCreatorFactory('USER_PAGE');

const loadPageState = actionCreator<UserPageLoadParams>('LOAD');
const loadUsers = actionCreator<UserParams[]>('LOAD_USERS');
const updateUser = actionCreator<UpdateUserParams>('UPDATE_USER');
const addUser = actionCreator<UserParams>('ADD_USER');
const setOnValidation = actionCreator<boolean>('SET_ON_VALIDATION');

export { loadPageState, loadUsers, updateUser, addUser, setOnValidation };
