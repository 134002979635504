// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__hidden{display:none}.src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__root{width:100%}.src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__link{text-decoration:none;color:inherit}.src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__name{height:32px;margin-bottom:8px;color:#4a4a4a;font-size:14px;font-weight:normal}.src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__activityCount{color:#9b9b9b;font-size:12px;font-weight:normal}.src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__userCount{color:#9b9b9b;font-size:12px;font-weight:normal}\n", "",{"version":3,"sources":["webpack://./src/modules/common/mixins/mixin.scss","webpack://./src/modules/OrganizationsPage/OrganizationCard/OrganizationCard.scss"],"names":[],"mappings":"AAYA,0EACI,YAAa,CCXjB,wEACI,UAAW,CACd,wEAGG,oBAAqB,CAErB,aAAc,CACjB,wEAGG,WAAY,CACZ,iBAAkB,CAElB,aAAc,CAEd,cAAe,CACf,kBAAmB,CACtB,iFAGG,aAAc,CAEd,cAAe,CACf,kBAAmB,CACtB,6EAGG,aAAc,CAEd,cAAe,CACf,kBAAmB","sourcesContent":["$Green: #519b0b;\n$DeepGreen: #3b8200;\n$LightGreen: #64ad1e;\n$Approval: #4a90e2;\n$Reassigned: $Green;\n$Assigned: $Green;\n$Approved: $DeepGreen;\n$InProgress: #f5a623;\n$Cancelled: #d0021b;\n$Draft: #9b9b9b;\n\n\n.hidden {\n    display: none;\n}\n\n@mixin validateError {\n    border: 1px solid $Cancelled;\n}\n","@import '../../common/mixins/mixin';\n\n.root {\n    width: 100%;\n}\n\n.link {\n    text-decoration: none;\n\n    color: inherit;\n}\n\n.name {\n    height: 32px;\n    margin-bottom: 8px;\n\n    color: #4a4a4a;\n\n    font-size: 14px;\n    font-weight: normal;\n}\n\n.activityCount {\n    color: #9b9b9b;\n\n    font-size: 12px;\n    font-weight: normal;\n}\n\n.userCount {\n    color: #9b9b9b;\n\n    font-size: 12px;\n    font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__hidden",
	"root": "src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__root",
	"link": "src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__link",
	"name": "src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__name",
	"activityCount": "src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__activityCount",
	"userCount": "src-modules-OrganizationsPage-OrganizationCard-_OrganizationCard__userCount"
};
export default ___CSS_LOADER_EXPORT___;
