import * as React from 'react';
import autobind from 'autobind-decorator';
import { connect, Dispatch } from 'react-redux';

import { SelectMenuItemParams } from '../../common/SelectMenu';
import { AccountStatus, UserAttributes } from '@sm/types/admin';
import { UpdateUserParams } from '../../../store/userPage/types';

import { UserRow } from './UserRow';
import { UserApi } from '../../../api';
import { updateUser } from '../../../store/userPage/actions';

interface Props extends Partial<DispatchProps> {
    user: UserAttributes;
}

interface DispatchProps {
    updateUser: (user: UpdateUserParams) => void;
}

interface State {
    isOnEdit: boolean;
}

@(connect(null, mapDispatchToProps) as any)
export class UserRowContainer extends React.Component<Props, State> {
    public state: State = {
        isOnEdit: false,
    };

    public componentWillReceiveProps(newProps: Props) {
        this.setState({ isOnEdit: false });
    }

    public render(): JSX.Element {
        const { user } = this.props;
        const { isOnEdit } = this.state;
        const selectMenuParams = this.makeSelectMenuParams();

        return React.createElement(UserRow, {
            user,
            selectMenuParams,
            isOnEdit,
            onCancelClick: this.hideEditForm,
        });
    }

    @autobind
    protected showEditForm() {
        this.setState({ isOnEdit: true });
    }

    @autobind
    protected hideEditForm() {
        this.setState({ isOnEdit: false });
    }

    @autobind
    protected async setBlockStatus() {
        const {
            user: { id },
            updateUser,
        } = this.props;

        const { status } = await UserApi.setUserAccountAccessDeniedStatus(id);

        updateUser({ id, status });
    }

    @autobind
    protected async setRenewalRequestedStatus() {
        const {
            user: { id },
            updateUser,
        } = this.props;

        const { status } = await UserApi.setUserAccountRenewalRequestedStatus(id);

        updateUser({ id, status });
    }

    @autobind
    protected async setActive() {
        const {
            user: { id },
            updateUser,
        } = this.props;

        const { status } = await UserApi.setActive(id);

        updateUser({ id, status });
    }

    private makeSelectMenuParams(): SelectMenuItemParams[] {
        const { user } = this.props;
        const params = [
            {
                label: 'Профиль пользователя',
                onClick: this.showEditForm,
            },
        ];

        switch (user.status) {
            case AccountStatus.ACTIVE:
                params.push({
                    label: 'Заблокировать',
                    onClick: this.setBlockStatus,
                });
                break;
            case AccountStatus.ACCESS_DENIED:
                params.push({
                    label: 'Ожидание продления',
                    onClick: this.setRenewalRequestedStatus,
                });
                break;
            case AccountStatus.RENEWAL_REQUESTED:
                params.push({
                    label: 'Заблокировать',
                    onClick: this.setBlockStatus,
                });

                if (new Date(user.activeUntil) > new Date()) {
                    params.push({
                        label: 'Активировать',
                        onClick: this.setActive,
                    });
                }

                break;
            case AccountStatus.EXPIRED:
                params.push({
                    label: 'Заблокировать',
                    onClick: this.setBlockStatus,
                });
                break;
            case AccountStatus.BLOCKED_FOR_INCORRECT_CREDENTIALS:
                params.push(
                    // {
                    //     label: 'Сбросить пароль',
                    //     onClick: this.resetPassword
                    // },
                    {
                        label: 'Заблокировать',
                        onClick: this.setBlockStatus,
                    },
                );
                break;
            case AccountStatus.NEED_UPDATE_PASSWORD:
                params.push({
                    label: 'Заблокировать',
                    onClick: this.setBlockStatus,
                });
                break;
            default:
                break;
        }

        return params;
    }
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        updateUser: (user: UpdateUserParams) => dispatch(updateUser(user)),
    };
}
