import { actionCreatorFactory } from 'typescript-fsa';

import * as Organization from '@sm/types/admin';

import { UpdateOrganizationParams, AddOrganizationParams } from '../types';

const actionCreator = actionCreatorFactory('ORGANIZATION');

const load = actionCreator<Organization.OrganizationParams[]>('LOAD');
const loadCurrentOrganization = actionCreator<Organization.OrganizationParams>('LOAD_CURRENT_ORGANIZATION');
const updateOrganization = actionCreator<UpdateOrganizationParams>('UPDATE_ORGANIZATION');
const updateCurrentOrganization = actionCreator<UpdateOrganizationParams>('UPDATE_CURRENT_ORGANIZATION');
const addOrganization = actionCreator<AddOrganizationParams>('ADD_ORGANIZATION');
const removeOrganization = actionCreator<string>('REMOVE_ORGANIZATION');
const setCurrentOrganizationId = actionCreator<string>('SET_CURRENT_ORGANIZATION_ID');
const resetCurrentOrganization = actionCreator('RESET_CURRENT_ORGANIZATION');

export {
    load,
    loadCurrentOrganization,
    updateOrganization,
    addOrganization,
    removeOrganization,
    updateCurrentOrganization,
    setCurrentOrganizationId,
    resetCurrentOrganization,
};
