// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-common-Card-_Card__root{position:relative;box-sizing:border-box;width:240px;height:inherit;background-color:#ffffff;box-shadow:0 2px 10px 0 rgba(0,0,0,0.1)}.src-modules-common-Card-_Card__contentWrapper{padding:16px 30px 16px 16px}.src-modules-common-Card-_Card__selectMenu{position:absolute;top:5px;right:0;display:flex;width:36px;align-items:flex-end}.src-modules-common-Card-_Card__selectMenuWrapper{width:230px;height:42px}\n", "",{"version":3,"sources":["webpack://./src/modules/common/Card/Card.scss"],"names":[],"mappings":"AAAA,qCACI,iBAAkB,CAElB,qBAAsB,CACtB,WAAY,CACZ,cAAe,CAEf,wBAAyB,CACzB,uCAA2C,CAC9C,+CAGG,2BAA4B,CAC/B,2CAGG,iBAAkB,CAClB,OAAQ,CACR,OAAQ,CAER,YAAa,CAEb,UAAW,CAEX,oBAAqB,CACxB,kDAGG,WAAY,CACZ,WAAY","sourcesContent":[".root {\n    position: relative;\n\n    box-sizing: border-box;\n    width: 240px;\n    height: inherit;\n\n    background-color: #ffffff;\n    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);\n}\n\n.contentWrapper {\n    padding: 16px 30px 16px 16px;\n}\n\n.selectMenu {\n    position: absolute;\n    top: 5px;\n    right: 0;\n\n    display: flex;\n\n    width: 36px;\n\n    align-items: flex-end;\n}\n\n.selectMenuWrapper {\n    width: 230px;\n    height: 42px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-common-Card-_Card__root",
	"contentWrapper": "src-modules-common-Card-_Card__contentWrapper",
	"selectMenu": "src-modules-common-Card-_Card__selectMenu",
	"selectMenuWrapper": "src-modules-common-Card-_Card__selectMenuWrapper"
};
export default ___CSS_LOADER_EXPORT___;
