import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Header, HeaderData } from './Header';
import { AuthApi } from '../../../api';

interface Props extends HeaderData, RouteComponentProps<{}> {}

class HeaderContainer extends React.Component<Props> {
    public render(): JSX.Element {
        return React.createElement(Header, {
            pageLabel: this.props.pageLabel,
            withoutBackButton: this.props.withoutBackButton,
            previousLabel: this.props.previousLabel,
            previousUrl: this.props.previousUrl,
            onLogoutButtonClickHandler: AuthApi.logout,
        });
    }
}

const HeaderContainerComposed = withRouter(HeaderContainer);

export { HeaderContainerComposed as HeaderContainer };
