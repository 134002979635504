import * as moment from 'moment';
moment.locale('ru');

export class Utils {
    public static getItemById(items: any[], id: number | string) {
        let result = items.find((item) => item.id == id);
        for (let i = 0, n = items.length, item; !result && i < n; i++) {
            item = items[i];
            result = item.subDepartments ? Utils.getItemById(item.subDepartments, id) : null;
        }
        return result;
    }

    public static removeItemById(itemsArray: any[], id: number | string) {
        return itemsArray.filter((item) => item.id != id);
    }

    public static updateItemById(itemsArray: any[], id: number | string, newParams: any) {
        return itemsArray.map((item) => (item.id == id ? { ...item, ...newParams } : item));
    }

    public static getItemsByIdAndFieldName(itemsArray: any[], id: number, fieldName: string) {
        return itemsArray.filter((item) => item[fieldName] == id) || {};
    }

    public static formatDateToM(date: string): string {
        moment.locale('ru');
        return moment(date).format('MMMM');
    }

    public static formatDateToMY(date: string): string {
        moment.locale('ru');
        return moment(date).format('MMMM YYYY');
    }

    public static formatDateToDMY(date: string | Date): string {
        moment.locale('ru');
        const formatedDate = moment(date).format('D/MMM/YY');
        return formatedDate.replace('.', '');
    }

    /**
     * Titles should be in subjective, genitive and accusative case,
     * example: ['арбуз', 'арбуза', 'арбузов']
     */
    public static getDeclensionByNumber(value: number, titles: string[]) {
        /* tslint:disable:no-magic-numbers */
        const cases = [2, 0, 1, 1, 1, 2];

        const titleIndex = value % 100 > 4 && value % 100 < 20 ? 2 : cases[value % 10 < 5 ? value % 10 : 5];
        /* tslint:enable:no-magic-numbers */

        return titles[titleIndex];
    }
}

/**
 * Titles should be in subjective, genitive and accusative case,
 * example: ['арбуз', 'арбуза', 'арбузов']
 */
export function getDeclension(value: number, titles: string[]) {
    /* tslint:disable:no-magic-numbers */
    const cases = [2, 0, 1, 1, 1, 2];

    const titleIndex = value % 100 > 4 && value % 100 < 20 ? 2 : cases[value % 10 < 5 ? value % 10 : 5];
    /* tslint:enable:no-magic-numbers */

    return titles[titleIndex];
}
