import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import * as lodash from 'lodash';

import { UserSubordination, UserParams } from '@sm/types/admin';

import { StoreState } from '../../';
import { Utils } from '../../../modules/common/Utils';

const getUserSubordinationState = (state: StoreState): UserSubordination => state.common.userSubordination;

export const getUserId = createSelector(getUserSubordinationState, (userSubordination: UserSubordination): number => {
    return userSubordination.userId;
});

export const getSubordinates = createSelector(
    getUserSubordinationState,
    (userSubordination: UserSubordination): UserParams[] => {
        return userSubordination.subordination || [];
    },
);

export const getSubordinateIds = createSelector(getSubordinates, (subordinates: UserParams[]): number[] => {
    return subordinates.map((subordinate) => subordinate.id);
});

export const getSubordinateById = createCachedSelector(
    getSubordinates,
    (state: StoreState, id: number): number => id,
    (subordinates: UserParams[], id: number): UserParams => {
        return Utils.getItemById(subordinates, id);
    },
)((state: StoreState, id: number): number => id);

export const getGroupUsers = createSelector(
    getUserSubordinationState,
    (userSubordination: UserSubordination): UserParams[] => {
        return userSubordination.departmentUsers || [];
    },
);

export const getAvailableGroupUsers = createSelector(
    getGroupUsers,
    getSubordinates,
    (groupUsers: UserParams[], subordinates: UserParams[]): UserParams[] => {
        return lodash.differenceBy(groupUsers, subordinates, (user) => user.id);
    },
);

export const getGroupUserIds = createSelector(getGroupUsers, (groupUsers: UserParams[]): number[] => {
    return groupUsers.map((groupUser) => groupUser.id);
});

export const getGroupUserById = createCachedSelector(
    getGroupUsers,
    (state: StoreState, id: number): number => id,
    (groupUsers: UserParams[], id: number): UserParams => {
        return Utils.getItemById(groupUsers, id);
    },
)((state: StoreState, id: number): number => id);
