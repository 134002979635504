import * as React from 'react';
import { render } from 'react-dom';
import * as moment from 'moment';

import { App } from './modules/common/App';

moment.locale('ru');

const app = <App />;
const root = document.getElementById('root');
render(app, root);
