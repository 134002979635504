// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-UsersPage-UserForm-_UserForm__hidden{display:none}.src-modules-UsersPage-UserForm-_UserForm__root{display:flex;flex-direction:column;padding:40px 40px 40px 28px;background-color:#ffffff;box-shadow:0 2px 10px 0 rgba(0,0,0,0.1)}.src-modules-UsersPage-UserForm-_UserForm__titleWrapper{margin-bottom:32px;text-align:left;text-indent:12px;color:#3b8200;font-size:18px;font-weight:500}.src-modules-UsersPage-UserForm-_UserForm__navigationButtons{display:flex;width:200px;height:24px;margin-bottom:32px;font-size:12px;align-self:center}\n", "",{"version":3,"sources":["webpack://./src/modules/common/mixins/mixin.scss","webpack://./src/modules/UsersPage/UserForm/UserForm.scss"],"names":[],"mappings":"AAYA,kDACI,YAAa,CCXjB,gDACI,YAAa,CACb,qBAAsB,CAEtB,2BAA4B,CAE5B,wBAAyB,CACzB,uCAA2C,CAC9C,wDAGG,kBAAmB,CAEnB,eAAgB,CAChB,gBAAiB,CAEjB,aDjBe,CCmBf,cAAe,CACf,eAAgB,CACnB,6DAGG,YAAa,CAEb,WAAY,CACZ,WAAY,CACZ,kBAAmB,CAEnB,cAAe,CAEf,iBAAkB","sourcesContent":["$Green: #519b0b;\n$DeepGreen: #3b8200;\n$LightGreen: #64ad1e;\n$Approval: #4a90e2;\n$Reassigned: $Green;\n$Assigned: $Green;\n$Approved: $DeepGreen;\n$InProgress: #f5a623;\n$Cancelled: #d0021b;\n$Draft: #9b9b9b;\n\n\n.hidden {\n    display: none;\n}\n\n@mixin validateError {\n    border: 1px solid $Cancelled;\n}\n","@import '../../common/mixins/mixin';\n\n.root {\n    display: flex;\n    flex-direction: column;\n\n    padding: 40px 40px 40px 28px;\n\n    background-color: #ffffff;\n    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);\n}\n\n.titleWrapper {\n    margin-bottom: 32px;\n\n    text-align: left;\n    text-indent: 12px;\n\n    color: $DeepGreen;\n\n    font-size: 18px;\n    font-weight: 500;\n}\n\n.navigationButtons {\n    display: flex;\n\n    width: 200px;\n    height: 24px;\n    margin-bottom: 32px;\n\n    font-size: 12px;\n\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "src-modules-UsersPage-UserForm-_UserForm__hidden",
	"root": "src-modules-UsersPage-UserForm-_UserForm__root",
	"titleWrapper": "src-modules-UsersPage-UserForm-_UserForm__titleWrapper",
	"navigationButtons": "src-modules-UsersPage-UserForm-_UserForm__navigationButtons"
};
export default ___CSS_LOADER_EXPORT___;
