import * as React from 'react';
import { connect, Dispatch } from 'react-redux';
import autobind from 'autobind-decorator';

import { ErrorHandler } from './ErrorHandler';
import { StoreState } from '../../../store';
import { removeError } from '../../../store/common/actions';
import { getErrorCode } from '../../../store/common/selector';

interface Props extends Partial<MapProps>, Partial<DispatchProps> {}

interface MapProps {
    errorCode: string;
}

interface DispatchProps {
    unsetErrorState: () => void;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class ErrorHandlerContainer extends React.PureComponent<Props> {
    public render() {
        const { errorCode, children } = this.props;

        return React.createElement(ErrorHandler, {
            errorCode,
            children,
            onFirstButtonClick: this.onFirstButtonClick,
        });
    }

    @autobind
    protected onFirstButtonClick(): void {
        this.props.unsetErrorState();
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        errorCode: getErrorCode(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        unsetErrorState: () => dispatch(removeError()),
    };
}
