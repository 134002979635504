import * as React from 'react';
import autobind from 'autobind-decorator';

import { SelectMenu, SelectMenuParams } from './SelectMenu';

interface SelectMenuContainerProps extends SelectMenuParams {
    stopMenuClickEventPropagation?: boolean;
}

interface SelectMenuContainerState {
    isOpen: boolean;
}

export class SelectMenuContainer extends React.Component<SelectMenuContainerProps> {
    public state: SelectMenuContainerState;

    constructor(props: SelectMenuContainerProps) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    public render(): JSX.Element {
        return React.createElement(SelectMenu, {
            items: this.props.items,
            isOpen: this.state.isOpen,
            onOpenIconClick: this.handleOpenClick,
            onClick: this.handleItemClick,
            openerIcon: this.props.openerIcon,
            openerClass: this.props.openerClass,
        });
    }

    @autobind
    protected handleOpenClick(event: React.MouseEvent<HTMLDivElement>) {
        this.setState({
            isOpen: !this.state.isOpen,
        });

        if (this.props.stopMenuClickEventPropagation) {
            event.stopPropagation();
        }
    }

    @autobind
    protected handleItemClick(itemOnClick: () => void, event: React.MouseEvent<HTMLDivElement>) {
        this.setState({
            isOpen: !this.state.isOpen,
        });

        itemOnClick();

        if (this.props.stopMenuClickEventPropagation) {
            event.stopPropagation();
        }
    }
}
