import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import * as lodash from 'lodash';

import { UserAttributes } from '@sm/types/admin';
import { StoreState } from '../../';
import { UserPageState } from '../types';

import { Utils } from '../../../modules/common/Utils';

const getUserPageState = (state: StoreState): UserPageState => state.userPage;

export const getUsers = createSelector(getUserPageState, (state: UserPageState): UserAttributes[] => {
    return lodash.sortBy(state.users, 'secondName');
});

export const getUsersIds = createSelector(getUsers, (users: UserAttributes[]): number[] => {
    return users.map((user) => user.id);
});

export const getUser = createCachedSelector(
    getUsers,
    (state: StoreState, id: number): number => id,
    (users: UserAttributes[], id: number): UserAttributes => {
        return Utils.getItemById(users, id);
    },
)((state: StoreState, id: number): number => id);

export const getOnValidation = createSelector(getUserPageState, (state: UserPageState): boolean => {
    return state.onValidation;
});
