import { UserParams, CreateUserParams, UpdateUserAttributes } from '@sm/types/admin';

import { axios } from '../lib/axios';

const USER_URL = '/api/user';

export class UserApi {
    public static async getUsers(organizationId: string): Promise<UserParams[]> {
        const params = { organizationIds: organizationId };

        const { data } = await axios.get(USER_URL, { params });

        return data;
    }

    public static async createUser(user: CreateUserParams): Promise<UserParams> {
        const res = await axios.post(`${USER_URL}`, { ...user });

        return res.data;
    }

    public static async updateUser(userId: number, params: UpdateUserAttributes): Promise<UserParams> {
        const res = await axios.put(`${USER_URL}/${userId}`, { ...params });

        return res.data;
    }

    public static async setUserAccountRenewalRequestedStatus(userId: number): Promise<UserParams> {
        const res = await axios.put(`${USER_URL}/${userId}/renewal_requested`);

        return res.data;
    }

    public static async setUserAccountAccessDeniedStatus(userId: number): Promise<UserParams> {
        const res = await axios.put(`${USER_URL}/${userId}/access_denied`);

        return res.data;
    }

    public static async setActive(userId: number): Promise<UserParams> {
        const res = await axios.put(`${USER_URL}/${userId}/active`);

        return res.data;
    }
}
