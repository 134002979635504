import * as lodash from 'lodash';

import { CreateUserParams } from '@sm/types/admin';

import { Validation } from '../../common/Validation';

const MIN_NAME_LENGTH = 1;

export class UserValidation {
    public static validateUser(user: CreateUserParams, usedEmails?: string[]): boolean {
        return (
            UserValidation.validateName(user.firstName) &&
            UserValidation.validateName(user.secondName) &&
            UserValidation.validatePhone(user.phoneNumber) &&
            UserValidation.validateEmail(user.email, usedEmails)
        );
    }

    public static validateName(name: string): boolean {
        return name.length > MIN_NAME_LENGTH;
    }

    public static validatePhone(phone: string): boolean {
        return Validation.validatePhoneNumber(phone);
    }

    public static validateEmail(email: string, usedEmails?: string[]): boolean {
        const isEmail = Validation.validateEmail(email);
        const notUsed = usedEmails ? !lodash.includes(usedEmails, email) : true;

        return isEmail && notUsed;
    }
}
