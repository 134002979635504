import * as React from 'react';

import * as style from './Card.scss';

import { Shadow } from '@sm/ui';

import { SelectMenu, SelectMenuItemParams } from '../SelectMenu';

interface CardProps {
    children: JSX.Element | JSX.Element[];
    menuProps?: SelectMenuItemParams[];
}

export function Card({ children, menuProps = [] }: CardProps): JSX.Element {
    return (
        <div className={style.root}>
            <Shadow>
                <div className={style.contentWrapper}>{children}</div>

                <div className={style.selectMenu}>
                    <div className={style.selectMenuWrapper}>
                        <SelectMenu stopMenuClickEventPropagation items={menuProps} />
                    </div>
                </div>
            </Shadow>
        </div>
    );
}
