import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect, Dispatch } from 'react-redux';

import { OrganizationParams } from '@sm/types/admin';

import { StoreState } from '../../store';
import { load } from '../../store/organization/actions';
import { getOrganizationList } from '../../store/organization/selector';
import { OrganizationApi } from '../../api';

import { PageProps } from '../common/Page';
import { OrganizationsPage } from './OrganizationsPage';

interface Props extends PageProps, Partial<DispatchProps>, Partial<MapProps> {}

interface MapProps {
    organizations: OrganizationParams[];
}

interface DispatchProps {
    load(organizations: OrganizationParams[]): void;
}

interface State {
    preloader: boolean;
}

@(connect(mapStateToProps, mapDispatchToProps) as any)
export class OrganizationsPageContainer extends React.Component<Props, State> {
    public state: State = { preloader: true };

    public async componentDidMount(): Promise<void> {
        const organizations = await OrganizationApi.getList();

        this.props.load(organizations);

        this.setState({ preloader: false });
    }

    public render(): JSX.Element {
        const { organizations } = this.props;
        const { preloader } = this.state;

        return React.createElement(OrganizationsPage, { organizations, preloader });
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        organizations: getOrganizationList(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return bindActionCreators({ load }, dispatch);
}
