import * as React from 'react';
import { Popup } from '@sm/ui';

import * as style from './UsersPage.scss';

import { OrganizationParams } from '@sm/types/admin';

import { Page } from '../common/Page';
import { UsersTable } from './UsersTable';
import { CreateUserForm } from './UserForm';
import { TopLine } from './TopLine';

interface Props {
    organization: OrganizationParams;
    preloader: boolean;
    isNewUserOpen: boolean;
    showUserForm(): void;
    hideUserForm(): void;
}

export function UsersPage({ organization, preloader, isNewUserOpen, showUserForm, hideUserForm }: Props): JSX.Element {
    return (
        <Page
            withoutNavigation
            pageLabel={organization.name}
            previousLabel="Организации"
            previousUrl="/"
            preloader={preloader}
        >
            {!preloader && (
                <div className={style.root}>
                    {isNewUserOpen && (
                        <Popup onOutOfContentClick={hideUserForm}>
                            <CreateUserForm onCancelClick={hideUserForm} />
                        </Popup>
                    )}
                    <div className={style.contentWrapper}>
                        <TopLine onNewUserButtonClick={showUserForm} />
                        <UsersTable />
                    </div>
                </div>
            )}
        </Page>
    );
}
