import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect, Dispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { isEmpty } from 'lodash';
import autobind from 'autobind-decorator';

import { OrganizationParams } from '@sm/types/admin';

import { StoreState } from '../../store';
import { loadPageState } from '../../store/userPage/actions';
import { UserPageLoadParams } from '../../store/userPage/types';
import { getOrganizationById, getCurrentOrganization } from '../../store/organization/selector';
import { loadCurrentOrganization } from '../../store/organization/actions';
import { UserApi, OrganizationApi } from '../../api';

import { PageProps } from '../common/Page';
import { UsersPage } from './UsersPage';

interface Props extends PageProps, MapProps, DispatchProps, RouteComponentProps<RouteParams> {}

interface RouteParams {
    organizationId: string;
}

interface MapProps {
    organization: OrganizationParams;
}

interface DispatchProps {
    loadPageState(pageState: UserPageLoadParams): void;
    loadCurrentOrganization(organization: OrganizationParams): void;
}

interface State {
    preloader: boolean;
    isNewUserOpen: boolean;
}

@(connect(mapStateTpProps, mapDispatchToProps) as any)
export class UsersPageContainer extends React.Component<Props, State> {
    public state: State = {
        preloader: true,
        isNewUserOpen: false,
    };

    public async componentDidMount() {
        const { match, organization } = this.props;
        const { organizationId } = match.params;

        const [users, currentOrganization] = await Promise.all([
            UserApi.getUsers(organizationId),
            isEmpty(organization) ? OrganizationApi.getById(organizationId) : organization,
        ]);

        this.props.loadPageState({ users });
        this.props.loadCurrentOrganization(currentOrganization);

        this.setState({ preloader: false });
    }

    public componentWillReceiveProps() {
        this.setState({ isNewUserOpen: false });
    }

    public render(): JSX.Element {
        return React.createElement(UsersPage, {
            ...this.state,
            organization: this.props.organization,
            showUserForm: this.showUserForm,
            hideUserForm: this.hideUserForm,
        });
    }

    @autobind
    public showUserForm() {
        this.setState({ isNewUserOpen: true });
    }

    @autobind
    public hideUserForm() {
        this.setState({ isNewUserOpen: false });
    }
}

function mapStateTpProps(state: StoreState, { match }: Props): MapProps {
    const { organizationId } = match.params;

    return {
        organization: getOrganizationById(state, organizationId) || getCurrentOrganization(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>): DispatchProps {
    return bindActionCreators(
        {
            loadPageState,
            loadCurrentOrganization,
        },
        dispatch,
    );
}
