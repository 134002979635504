import * as React from 'react';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';

import * as style from './Navigation.scss';
import { locations } from '../Router';

interface Props extends RouteComponentProps<RouteParams> {}

interface RouteParams {
    organizationId: string;
}

function Navigation({ match }: Props) {
    return (
        <div className={style.root}>
            {locations.map(
                (item) =>
                    !item.sub && (
                        <NavLink
                            exact
                            to={
                                match.params.organizationId
                                    ? item.path.replace(':organizationId', match.params.organizationId)
                                    : item.path
                            }
                            key={item.path}
                            className={style.link}
                            activeClassName={style.activeLink}
                        >
                            <div className={style.label}>{item.pageLabel}</div>
                        </NavLink>
                    ),
            )}
        </div>
    );
}

const WithRouterNavigation = withRouter(Navigation);
export { WithRouterNavigation as Navigation };
