import * as React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AuthErrorPage } from '../AuthErrorPage';

import { locations } from './locations';

import { RouteContainer } from './RouteContainer';
import { NotFoundPage } from '../../NotFoundPage';

export function Router(): JSX.Element {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/auth-error" component={AuthErrorPage} />

                {locations.map((location) => (
                    <RouteContainer exact key={location.path} {...location} />
                ))}
                <Route component={NotFoundPage} />
            </Switch>
        </BrowserRouter>
    );
}
