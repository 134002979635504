// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-modules-UsersPage-TopLine-_TopLine__root{display:flex;width:100%;max-width:1000px;height:50px;margin:auto;font-size:12px;justify-content:flex-end}.src-modules-UsersPage-TopLine-_TopLine__buttonBlock{display:flex}.src-modules-UsersPage-TopLine-_TopLine__buttonWrapper{width:141px;height:24px;margin-right:24px}.src-modules-UsersPage-TopLine-_TopLine__buttonWrapper:last-child{margin:0}\n", "",{"version":3,"sources":["webpack://./src/modules/UsersPage/TopLine/TopLine.scss"],"names":[],"mappings":"AAAA,8CACI,YAAa,CAEb,UAAW,CACX,gBAAiB,CACjB,WAAY,CACZ,WAAY,CAEZ,cAAe,CAEf,wBAAyB,CAC5B,qDAGG,YAAa,CAChB,uDAGG,WAAY,CACZ,WAAY,CACZ,iBAAkB,CAHtB,kEAMQ,QAAS","sourcesContent":[".root {\n    display: flex;\n\n    width: 100%;\n    max-width: 1000px;\n    height: 50px;\n    margin: auto;\n\n    font-size: 12px;\n\n    justify-content: flex-end;\n}\n\n.buttonBlock {\n    display: flex;\n}\n\n.buttonWrapper {\n    width: 141px;\n    height: 24px;\n    margin-right: 24px;\n\n    &:last-child {\n        margin: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "src-modules-UsersPage-TopLine-_TopLine__root",
	"buttonBlock": "src-modules-UsersPage-TopLine-_TopLine__buttonBlock",
	"buttonWrapper": "src-modules-UsersPage-TopLine-_TopLine__buttonWrapper"
};
export default ___CSS_LOADER_EXPORT___;
