import * as React from 'react';

import { PageProps } from '../Page';
import { UsersPage } from '../../UsersPage';
import { OrganizationsPage } from '../../OrganizationsPage';

export interface Location {
    path: string;
    component: React.ComponentType<PageProps>;
    pageLabel?: string;
    sub?: boolean;
    previousUrl?: string;
    previousLabel?: string;
}

export const locations: Location[] = [
    {
        path: '/',
        component: OrganizationsPage,
    },
    {
        path: '/organization/:organizationId/user',
        component: UsersPage,
    },
];
