import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { Button, ButtonTheme, FixedIcons } from '@sm/ui';
import * as style from './Header.scss';

export interface HeaderData {
    pageLabel: string;
    withoutBackButton?: boolean;
    previousUrl?: string;
    previousLabel?: string;
}

export interface HeaderProps extends HeaderData {
    avatarSrc?: string;
    onLogoutButtonClickHandler?: () => void;
}

export function Header({
    pageLabel,
    withoutBackButton,
    previousLabel,
    previousUrl,
    onLogoutButtonClickHandler,
}: HeaderProps) {
    return (
        <div className={style.root}>
            <NavLink to={'/'}>
                <div className={style.leftContent}>{FixedIcons.getLogoSvg()}</div>
            </NavLink>
            <div className={style.centerContent}>
                {!withoutBackButton && <NavBackButton previousLabel={previousLabel} previousUrl={previousUrl} />}
                <div className={style.textWrapper}>{pageLabel}</div>
            </div>
            <div className={style.rightContent}>
                <div className={style.buttonWrapper}>
                    <Button theme={ButtonTheme.PrimaryOutline} onClick={onLogoutButtonClickHandler}>
                        Выход
                    </Button>
                </div>
            </div>
        </div>
    );
}

interface NavBackButtonProps {
    previousLabel: string;
    previousUrl: string;
}

function NavBackButton({ previousLabel, previousUrl }: NavBackButtonProps): JSX.Element {
    return (
        <NavLink className={style.backBlock} to={previousUrl}>
            <div className={style.backIcon}>{FixedIcons.getLeftArrow()}</div>
            <div className={style.backLabel}>{previousLabel}</div>
        </NavLink>
    );
}
