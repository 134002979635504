import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { UserAttributes } from '@sm/types/admin';
import { UserPageState, UpdateUserParams, UserPageLoadParams } from '../types';

import { loadPageState, loadUsers, updateUser, addUser, setOnValidation } from '../actions';

const initialState: UserPageState = {
    users: [],
    onValidation: false,
};

export const userPageReducer = reducerWithInitialState(initialState)
    .case(loadPageState, load)
    .case(loadUsers, updateUsers)
    .case(updateUser, updateUserState)
    .case(addUser, addNewUser)
    .case(setOnValidation, changeOnValidation);

function load(state: UserPageState, payload: UserPageLoadParams) {
    return {
        ...state,
        ...payload,
    };
}

function updateUsers(state: UserPageState, users: UserAttributes[]) {
    return {
        ...state,
        users,
    };
}

function updateUserState(state: UserPageState, { ...changedUser }: UpdateUserParams) {
    const users = state.users.map((user) => (user.id == changedUser.id ? { ...user, ...changedUser } : user));

    return {
        ...state,
        users,
    };
}

function addNewUser(state: UserPageState, newUser: UserAttributes) {
    const users = [...state.users, newUser];
    return {
        ...state,
        users,
    };
}

function changeOnValidation(state: UserPageState, onValidation: boolean) {
    return {
        ...state,
        onValidation,
    };
}
